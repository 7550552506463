
// index.js or App.js
import { Link } from "react-router-dom";
import Coming from "../ComingSoon-01.svg"
import "./Home.css"



function HomePage() {
    return (
        <>

            <div className='app mt-3'>
                <h1 className='text-center'>
                    P2P Konnect
                </h1>
                <h2 className='text-center'>
                    <span>
                        Powered By VZone Solutions
                    </span>
                </h2>
                <div className='text-center'>

                    <img src={Coming} alt='' className="comingSoon-image" width={"50%"}></img>
                </div>
            </div>
            <div className="container-fluid">
             <div className="mt-md-3 mt-5">
<Link to="/terms-conditions" className="term_link" style={{color:"white", textAlign:"right"}}>
    Term and Conditions
</Link>
             </div>
            </div>
        </>
    );
}

export default HomePage;